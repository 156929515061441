<template>
    <v-container fluid dense>
        <v-data-table dense :loading="loading" :headers="headers" :items="items">
            <template v-slot:top>
                <v-row>
                    <v-col class="h3--text text-center font-weight-bold" :loading="loading" read-only>
                         {{ resultado }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:[`item.percent_total`]="{item}">
                <SaldoEx :url="'uteis/filtro-condicional/?table=producao-orcamentos'" :urlv="''" :lista="items" :objeto-current="item"/>
            </template>    
            <template v-slot:[`item.naoutilizados`]="{item}">
                {{item.qtd_evol - item.qtd_evoluidas}}
            </template>    
        </v-data-table>
    </v-container>
</template>
<script>
import api from '../../../http';

export default{
    name:'ProcedExpand',
    components:{
        SaldoEx:()=>import('./Saldo.vue')
    },
    props: {view:{type:String},url:{type:String}},
    data:()=>({headers:[], items:[], resultado:'RESUMO GERAL', loading:true}),
    methods:{
        async get_listas(url){
            const res = await api.get(`${url}`)
            try{
            
                this.items = res.data.map((e,i)=>{ 
                    e['id']=`evo${i}` 
                    return e
                })
                // const novoHeader = ['procedimento','qtd_assinadas','qtd_evol','qtd_evoluidas','ass_npg','evo_npg','percent_total']
                this.headers = 
                [
                    {text:'Procedimento', value:'procedimento'},
                    {text:'Qtd. de procedimentos geradas', value:'qtd_evol'},
                    {text:'Qtd. procedimentos evoluidos', value:'qtd_evoluidas'},
                    {text:'Qtd. procedimentos validados (Evolução + Assinatura)', value:'qtd_assinadas'},
                    {text:'Qtd. procedimentos não validados', value:'evo_npg'},
                    {text:'Qtd. procedimentos não utilizados', value:'naoutilizados'},
                    {text:'% de cobertura', value:'percent_total'},
                ]
            }
            catch{e=>{console.log(e)}}
            finally{ this.loading = false}
        }
    },
    mounted(){
        this.get_listas(this.url)
    }
}
</script>